export function apiGet(endpoint, cb) {
  fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer sk7JbAetASnDnH2YBFVkRqd284Gd3lunG05FzzDlLaK5uBdhojrPNu2aDl6g16X3gThtolvifVQYDp08x`
    },
  })
    .then(response => response.json())
    .then(result => {
      // console.log(result)
      cb(result)
    })
    .catch(error => {
      // console.error(error)
      cb(error)
    })
}

export function apiPost(endpoint, mutations, cb) {
  fetch(endpoint, {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer sk7JbAetASnDnH2YBFVkRqd284Gd3lunG05FzzDlLaK5uBdhojrPNu2aDl6g16X3gThtolvifVQYDp08x`,
    },
    body: JSON.stringify({ mutations }),
  })
    .then(response => response.json())
    .then(result => {
      console.log(result)
      cb(result)
    })
    .catch(error => {
      console.error(error)
      cb(error)
    })
}
