import React, { useState, useRef } from 'react';
import Helmet from 'react-helmet'
// import {useStaticQuery, graphql} from 'gatsby'
import { useEffect } from 'react';
import { apiGet } from '../lib/data'
import { JSON2CSV } from '../lib/helpers'
const { apiPwd } = require('../../config/website')

const Api = () => {
  // console.log(data.allSanityUser.nodes)
  // const {allSanityUser} = useStaticQuery(query)
  // console.log(allSanityUser)
  const endpointGetAll = `/.netlify/functions/get-all`
  const endpointReset = `/.netlify/functions/reset`

  const [pass, setPass] = useState("")
  const [state, setState] = useState([])
  const [isLogged, setIsLogged] = useState()
  const downloadBtn = useRef()

  const th = ["_createdAt", "_id", "last_name", "first_name", "email", "code_banquier", "contribution", "zoneIn"]

  useEffect(() => {
    if(isLogged){
      _pullAll()
    }
  }, [isLogged])

  useEffect(() => {
    if(state.length)_updateDownloadBtn()
  }, [state])

  const _updateDownloadBtn = () => {
    //DL > JSON
    // var data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(state));
    // downloadBtn.current.setAttribute("href", "data:"+data);
    // downloadBtn.current.setAttribute("download", "data.json");    
    const flatten = state.map(el => {
      return {
        _createdAt: el._createdAt,
        _id: el._id,
        last_name: el.last_name,
        first_name: el.first_name,
        email: el.email,
        code_banquier: el.code_banquier,
        contribution: el.contribution,
        zoneIn: el.zoneIn.title
      }
    })
    // console.log(flatten)
    const csv = JSON2CSV(flatten);
    const blob = new Blob(["\ufeff", csv]);
    const url = URL.createObjectURL(blob);
    downloadBtn.current.setAttribute("href", url);
    downloadBtn.current.setAttribute("download", "data.csv");  

  }

  const _onSubmit = (e) => {
    e.preventDefault();
    if(pass === apiPwd){
      setIsLogged(true)
    }
  }

  const _pullAll = () => {
    // const endpoint = 
    apiGet(endpointGetAll, (res) => {
      console.log(res)
      setState(res.result)
    })
  }

  
  const _serializeValue = (key,val) => {
    // console.log(key, val)
    // console.log(typeof val[key])
    if(typeof val[key] === 'object'){
      return val[key].title
    }else{
      return val[key]
    }
    
  }

  return (
    <div className="api">
      <Helmet >
        <body className={"template-api"} />
      </Helmet>

      <div className="container">
        <h1>api</h1>
        {!isLogged &&
          <form action="" onSubmit={_onSubmit}>
            <div className="form-row x xjb">
              <input 
                type="password" 
                name="passwd" 
                placeholder="Mot de passe"
                onChange={(e) => setPass(e.target.value)}
                />
              <button type="submit">
                <span className="icon icon-arrow-e"></span>
              </button>
            </div>
          </form>
        }
        {isLogged &&
        <>
          <div className="tar  mb1">
            <a href="" ref={downloadBtn} className="btn btn-primary outline">
              <span className={`mr1 `}>⤓</span>
              <span>Download csv</span>
            </a>
          </div>
          <table>
            <thead>
              <tr>
                {th.map((el,i) => (
                  <th key={i}>{el}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.map((_tr,i) => (
                <tr key={i}>
                  {th.map((_th,i) => (
                    <td key={i}>{_serializeValue(_th, _tr)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
        }
      </div>
    </div>
  );
};

export default Api;